import React, { Component } from "react"
// import { Link } from 'react-router-dom';
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { injectIntl } from "react-intl"

import content from "./homeTestiItem"

class HomeTestimonial extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(2, 2, 1),
    }

    return (
      <>
        <Slider
          {...settings}
          className="testimonial-five primary dots-style-3 btn-style-2 out btn-color btn-white"
        >
          {content.map((item, id) => (
            <div
              className="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="testimonial-14 quote-left testimonial-container">
                <div className="testimonial-detail clearfix text-white testi-detail">
                  <h5 className="testimonial-name m-t10 m-b5">{item.author}</h5>
                  {this.props.intl.locale === "en" ? (
                    <span className="home-testi testimonial-position">
                      {item.designation}
                    </span>
                  ) : this.props.intl.locale === "cn" ? (
                    <span className="home-testi testimonial-position">
                      {item.jabatanCN}
                    </span>
                  ) : (
                    <span className="home-testi testimonial-position">
                      {item.jabatan}
                    </span>
                  )}
                </div>
                <div className="testimonial-text testi-desc">
                    <p 
                        className={
                          "text-center" +
                          (this.props.intl.locale === "en" ? "" : " font-italic")
                        }>
                      {item.description}
                    </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default injectIntl(HomeTestimonial)
