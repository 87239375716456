import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

// import service1 from "../../images/home/service-1.webp"
import service2 from "../../images/home/service-2.webp"

const OurCapabilities = ({ service1Img, service2Img, service3Img }) => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  return (
    <div className="section-full bg-gray content-inner">
      <div className="container">
        <div className="section-head text-center">
          <h2 className="title mt-4">
            {intl.formatMessage({ id: "home.ourcapabilities" })}
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="blog-post blog-grid blog-rounded blog-effect1">
              <div className="dlab-post-media dlab-post-media-home">
                <Link to={`${locale}/products-and-services/services/cmo`}>
                  <GatsbyImage
                    image={getImage(service1Img.images)}
                    alt="CMO Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div className="dlab-info p-a20 border-1">
                <div className="dlab-post-title">
                  <h4 className="post-title">
                    <Link to={`${locale}/products-and-services/services/cmo`}>
                      {intl.formatMessage({ id: "home.cmo.title" })}
                    </Link>
                  </h4>
                </div>
                <div className="dlab-post-text">
                  {/* jadiin fill and finish italic */}
                  <p>{intl.formatMessage({ id: "home.cmo.desc" })}</p>
                </div>
                <div className="dlab-post-readmore">
                  <Link
                    to={`${locale}/products-and-services/services/cmo`}
                    title="READ MORE"
                    rel="bookmark"
                    className="site-button btnhover20"
                  >
                    {intl.formatMessage({ id: "home.ourcapabilitiesbtn" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="blog-post blog-grid blog-rounded blog-effect1">
              <div className="dlab-post-media dlab-post-media-home">
                <Link
                  to={`${locale}/products-and-services/services/toll-in-testing`}
                >
                  <GatsbyImage
                    image={getImage(service2Img.images)}
                    alt="Toll-in Testing Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div className="dlab-info p-a20 border-1">
                <div className="dlab-post-title">
                  <h4 className="post-title">
                    <Link
                      to={`${locale}/products-and-services/services/toll-in-testing`}
                    >
                      {intl.formatMessage({ id: "header.tollintesting" })}
                    </Link>
                  </h4>
                </div>
                <div className="dlab-post-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "home.tollintesting.desc",
                      }),
                    }}
                  >
                  </p>
                </div>
                <div className="dlab-post-readmore">
                  <Link
                    to={`${locale}/products-and-services/services/toll-in-testing`}
                    title="READ MORE"
                    rel="bookmark"
                    className="site-button btnhover20"
                  >
                    {intl.formatMessage({ id: "home.ourcapabilitiesbtn" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="blog-post blog-grid blog-rounded blog-effect1">
              <div className="dlab-post-media dlab-post-media-home">
                <Link
                  to={`${locale}/products-and-services/services/process-analytical-development`}
                >
                  <GatsbyImage
                    image={getImage(service3Img.images)}
                    alt="Process and Analytical Development Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div className="dlab-info p-a20 border-1">
                <div className="dlab-post-title">
                  <h4 className="post-title">
                    <Link
                      to={`${locale}/products-and-services/services/process-analytical-development`}
                    >
                      {intl.formatMessage({
                        id: "header.processandanalyticaldevelopment",
                      })}
                    </Link>
                  </h4>
                </div>
                <div className="dlab-post-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "home.processandanalyticaldevelopment.desc",
                      }),
                    }}
                  ></p>
                </div>
                <div className="dlab-post-readmore">
                  <Link
                    to={`${locale}/products-and-services/services/process-analytical-development`}
                    title="READ MORE"
                    rel="bookmark"
                    className="site-button btnhover20"
                  >
                    {intl.formatMessage({ id: "home.ourcapabilitiesbtn" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurCapabilities
