import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import FloatingBtn from "../components/custom-components/floatingBtn"
import HomeTestimonial from "../components/custom-components/HomeTestimonial"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import OurCapabilities from "../components/custom-components/OurCapabilities"
import ScrollToTop from "../components/scrollToTop"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-index.css"

import NewsCard from "../components/custom-components/LastNewsCard"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import HomeBanner from "../components/custom-components/HomeBanner"
import { HomeBannerSliderContent } from "../components/SliderContent"

const Index = ({ data }) => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  const {
    service1,
    service2,
    service3,
    joinUsLeft,
    joinUsRight,
    joinUsCenter,
    joinUsCenterId,
    joinUsCenterCn,
  } = data

  return (
    <div className="skin-1 index">
      <SEO title={intl.formatMessage({ id: "header.home" })} />
      <Header indexPage />
      <FloatingBtn />
      <HomeBanner data={HomeBannerSliderContent} hideBtn />

      {/* kalbio at a glance */}

      <div
        className="section-full call-action style1 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.2s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h2 className="title">
                {intl.formatMessage({ id: "home.kalbioataglance.title" })}
              </h2>
              <p className="kalbio-glance-txt">
                {intl.formatMessage({ id: "home.kalbioataglance.desc" })}
              </p>
            </div>
            <div className="col-lg-3 d-flex">
              <Link
                to={`${locale}/about`}
                className="site-button black align-self-center ml-auto btnhover14"
              >
                {intl.formatMessage({ id: "home.kalbioataglance.btn" })}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* our capabilities */}

      <OurCapabilities
        service1Img={service1}
        service2Img={service2}
        service3Img={service3}
      />

      {/* client testimony */}

      <div className="section-full content-inner bg-white">
        <div className="container">
          <div className="section-head style2 text-center">
            <h2 className="title">
              {intl.formatMessage({ id: "home.whatpeoplearesaying" })}
            </h2>
          </div>
          <HomeTestimonial />
        </div>
      </div>

      {/* latest news */}

      <div
        className="section-full content-inner bg-gray wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="title">
              {intl.formatMessage({ id: "home.ourlatestnews" })}
            </h2>
          </div>

          <NewsCard
            data={intl.locale === "cn" ? data.newsCn.nodes : data.news.nodes}
            blogPostCls="post-style-1"
            imgEffect="rotate"
            noDes
          />
        </div>
      </div>

      {/* join us banner */}

      <div className="join-us">
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="section-full">
              <div className="row spno about-industry">
                <div
                  className="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div className="dlab-post-media">
                    <Link to={`${locale}/career-page`}>
                      <GatsbyImage image={getImage(joinUsLeft.images)} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div className="dlab-post-media">
                    <Link to={`${locale}/career-page`}>
                      {/* {intl.locale === "en" ? (
                        <GatsbyImage image={getImage(joinUsCenter.images)} alt="" />
                      ) : (
                        <GatsbyImage image={getImage(joinUsCenterId.images)} alt="" />
                      )} */}
                      <GatsbyImage 
                        image={
                          intl.locale === "en" ? getImage(joinUsCenter.images) : 
                          intl.locale === "id" ? getImage(joinUsCenterId.images) : 
                          getImage(joinUsCenterCn.images) } 
                        alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="dlab-post-media">
                    <Link to={`${locale}/career-page`}>
                      <GatsbyImage image={getImage(joinUsRight.images)} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}

export default Index

export const query = graphql`
  query LastNews($reg: String) {
    news: allContentfulNewsevents(
      limit: 6
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { regex: $reg } }
    ) {
      nodes {
        id
        title
        newsEventsDate(formatString: "DD MMMM YYYY")
        images {
          gatsbyImageData
        }
        slug
        description {
          raw
        }
        newsType
        eventsType
        node_locale
      }
    }
    newsCn: allContentfulCnNewsevents(
      limit: 6
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        id
        title
        newsEventsDate(formatString: "DD MMMM YYYY")
        images {
          gatsbyImageData
        }
        slug
        description {
          raw
        }
        newsType
        eventsType
        node_locale
      }
    }
    service1: contentfulHomeImages(title: { eq: "services-1" }) {
      title
      images {
        gatsbyImageData(height: 400)
      }
    }
    service2: contentfulHomeImages(title: { eq: "services-4" }) {
      title
      images {
        gatsbyImageData(height: 400)
      }
    }
    service3: contentfulHomeImages(title: { eq: "services-3" }) {
      title
      images {
        gatsbyImageData(height: 400)
      }
    }
    joinUsLeft: contentfulHomeImages(title: { eq: "join-us-left" }) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsRight: contentfulHomeImages(title: { eq: "join-us-right" }) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsCenter: contentfulHomeImages(title: { eq: "join-us-center" }) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsCenterId: contentfulHomeImages(
      title: { eq: "join-us-center" }
      node_locale: { eq: "id-ID" }
    ) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsCenterCn: contentfulCnHomeImages(title: {eq: "join-us-center-cn"}) {
      title
      images {
        gatsbyImageData
      }
    }
  }
`
