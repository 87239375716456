const content = [
  {
    author: `Zhao Yanqing`,
    designation: `President Director of Shandong Kexing Bioproducts Co. Ltd.`,
    jabatan: `Presiden Direktur Shandong Kexing Bioproducts Co. Ltd.`,
    jabatanCN: `Shandong Kexing Bioproducts Co. Ltd. 总裁`,
    description: `As a leading pharmaceutical enterprise in Southeast Asia, Kalbio Global Medika (KGM) is one of our most important business partner in Southeast Asia, maintained a mutually beneficial relationship with us for about 20 years. KGM is a good partner with honest and practical style, I believe that we will continue to go hand in hand and expand the scope of cooperation in the future to make a contribution to Indonesia and the whole human health with the support of the friendship bridge between China and Indonesia.`,
  },
  {
    author: `Du Kai`,
    designation: `CEO of Gan & Lee Pharmaceuticals`,
    jabatan: `CEO Gan & Lee Pharmaceuticals`,
    jabatanCN: `Gan & Lee Pharmaceuticals 首席执行官`,
    description: `Gan & Lee has been cooperating with KGM since 2011. With our joint efforts, Ezelin® has successfully been launched in Indonesia. Although both teams ever faced a lot of difficulties in many collaborative projects, KGM team always try their best to seek solutions and solve every problem carefully with the spirit of science. They are one of the most professional teams we have ever seen. We considerably value our partnership and also believe that Gan & Lee and KGM will usher in brighter win-win cooperation in the future.`,
  },
  {
    author: `Gladys Wang`,
    designation: `Head of Process Solutions, South Asia & Oceania Merck Chemicals And Life Sciences`,
    jabatan: `Kepala Process Solutions South Asia & Oceania Merck Chemicals And Life Sciences`,
    jabatanCN: `South Asia & Oceania Merck Chemicals And Life Sciences 工艺流程解决方案领导者`,
    description: `Merck has been partnering with PT Kalbio Global Medika (KGM) since 2016 to accelerate the development of the biotechnology medicine and manufacture of APIs of GMP standard locally in Indonesia. We share a common purpose to improve the public health and address unmet medical needs of the Indonesian people. KGM possesses a multi-disciplinary team, from R&D, manufacturing to regulatory with rich expertise in emerging technologies to improve research and development productivity. In our years of partnership, Merck has provided training and knowledge transfer in the areas of upstream, downstream to formulation application to enhance the capabilities of KGM’s scientists and engineers, and worked closely with KGM to explore innovative solutions to further optimize the API development and manufacturing process.`,
  },
]

export default content

